* {
  font-family: 'Open Sans', sans-serif;
}

html {
  scroll-behavior: auto !important;
  scroll-padding-top: 200px;
}

:root {
  --red: rgba(187, 21, 31);
  --bg-gradient: linear-gradient(150deg, rgba(255,255,255,1) 0%, rgba(189,32,27,1) 47%)
}

/* Shared Utility Classes */
/* @keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-right {
  0% {
    display: none;
    transform: translateX(30px);
  }

  100% {
    display: block;
    transform: translateX(0);
  }
}

.fade-up {
  animation: fade-up .5s ease-in-out;
}

.fade-right {
  animation: fade-right .5s ease-in-out;
} */

/* [data-id="fade-up"] {
  display: none;
} */

/* Header Section */
#nav {
  background: rgb(255,255,255);
  background: var(--bg-gradient);
  backdrop-filter: blur(5px);
  overflow-x: hidden;
}

.nav-links {
  color: #fff !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
  transition: 0.3s !important;
  position: relative;
  padding: 0 !important;
  width: max-content;
  text-decoration: none;
}

.nav-links:hover::after,
.active::after {
  content: '';
  position: absolute;
  animation: underline .3s forwards;
  left: 0;
  bottom: 0;
  height: 0.1em;
  background-color: #fff;
}

@keyframes underline {
  from {
    width: 10%;
  }
  to {
    width: 80%;
  }
}

/* .active::after {
  content: '';
  position: absolute;
   animation: underline .3s forwards; 
  /* left: 0;
  bottom: 0;
  height: 0.1em;
  background-color: #fff; 
} */

.nav-links::after,
.nav-links::after {
  opacity: 1;
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-nav:hover .nav-links {
  opacity: 0.35 !important;
}

.navbar-nav .nav-links:hover {
  opacity: 1 !important;
}

#myBtn{
  position: fixed;
  bottom: 50px;
  right: 80px;
  background-color: transparent;
  border: none;
  color: var(--red);
}

/* Hero Section */
#hero {
  background-image: url('./components/home-component/assets/images/pexels-fauxels-3184418.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  margin-top: 60px!important;
  height: 100vh !important;
  overflow-x: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  position: relative;
}

#hero::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.overlap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.alcon {
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff !important;
  z-index: 999;
}


.credential {
  font-size: 1.2rem !important;
  color: #fff !important;
  z-index: 999;
}

.left-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.right-container {
  text-align: right;
}


.left-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hero-img {
  width: 50%;
}

.background-container {
  height: 100vh !important;
  width: 100vw !important;
  background-image: url('./components/home-component/assets/images/pexels-lukas-684387.jpg') !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-size: cover !important;
  overflow-x: hidden !important;
  position: relative !important;
}

.background-container::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #000;
  opacity: .4;
}

.title {
  color: #fff;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#btn-client {
  font-size: 1rem;
  background: var(--bg-gradient);
  padding: 8px 20px;
  border: none;
  transition: .3s;
}

 #btn-solutions {
  background: var(--bg-gradient) !important;
  transition: .3s ease-in-out;
}

#btn-solutions:hover,
#btn-client:hover {
  transform: translateY(-2px);
  box-shadow: 0px 1px 5px #000;
}

#navigator {
  text-decoration: none;
  color: #000;
}

/* Solutions Section */
#solutions {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  overflow: hidden;
}

.cards {
  height: 300px !important;
  cursor: pointer;
  padding: 20px 10px;
  text-align: start !important;
}

.card-title {
  color: var(--darkRed) !important;
  font-weight: bold !important;
}

.card-text {
  color: var(--darkRed) !important;
  font-size: .9rem;
}

.truncate {
  text-overflow:ellipsis !important;
  overflow:hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  white-space: normal !important;
}

.cards {
  transition: .3s;
}

.cards > .read-more {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  width: max-content;
  border: none;
  align-self: center;
}

.cards:hover > .read-more {
  color: rgb(12, 80, 182);
  text-decoration: underline;
}

.cards:hover {
  transform: translateY(-10px) !important;

  box-shadow: 15px 13px 16px -6px rgba(125,113,113,0.73) !important;
  -webkit-box-shadow: 15px 13px 16px -6px rgba(125,113,113,0.73) !important;
  -moz-box-shadow: 15px 13px 16px -6px rgba(125,113,113,0.73) !important;
}

/* Client Section */
#clients {
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('./components/home-component/assets/images/pexels-fauxels-3184418.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.clients {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.clients > p {
  /* font-size: 1.5rem; */
  width: 80%;
  text-align: center;
}

/* Partners Section */
#partners {
  margin-top: 70px !important;
  margin-bottom: 120px !important;
}

.logos {
  width: 200px;
  aspect-ratio: 3/2;
  object-fit: contain;
  transition: 0.3s;
}

.col:hover > .logos {
  transform: scale(1.2);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: gray !important;
  border-radius: 10px;
}


/* JoinTeam Section */
#join{
  margin-top: 5rem;
  overflow: hidden;
}

.str{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.skew-img{
  -moz-transform: skew(-8deg, 0deg);
  -webkit-transform: skew(-8deg, 0deg);
  -o-transform: skew(-8deg, 0deg);
  -ms-transform: skew(-8deg, 0deg);
  transform: skew(-8deg, 0deg);
}

/* Footer Section */
#footer {
  background-color: #545557;
  color: #fff;
  overflow-x: hidden;
}

#footer > .container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px dashed #fff;
}

.fb-link,
.linkedin-link {
  color: #fff;
}

.links{
  list-style: none;
}

.footer-links{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fs-normal{
  font-weight: 350 !important;
}

.address{
  text-decoration: none;
  color:#fff;
}

.copyright{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(17, 26, 107);
  padding: 30px 0;
  font-size: 400;
  font-weight: bold
}

/* About Section */
#what-we-do {
  margin-top: 120px !important;
  margin-bottom: 50px !important;
}

#About {
  margin-bottom: 50px;
  overflow-x: hidden;
}

.about-header {
  background: rgb(241, 65, 65);
  position: relative;
  overflow-x: hidden;
}

.about-header > h1 {
  font-size: 400;
}

.header-title {
  font-family: 'Poppins', 'Open Sans' !important;
  font-size: 2.3rem;
  font-weight: bold;
  /* color: #fff; */
}

.about-header > p {
  background-color: rgb(59, 51, 51);
  color: #fff;
  flex: 1;
  margin-left: 20px;
  padding: 15px 20px;
  font-size: 1.1rem;
  border-bottom-left-radius: 50px;
  position: absolute;
  top: 0;
  right: 0;
}

.about-us {
  padding: 25px 10px;
}

.who-we-are {
  font-size: 2rem;
  font-weight: bold;
}

.postcard > p {
  background-color: var(--red);
  color: #fff;
  padding: 10px 15px;
}

.img-about-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* What we do section */
#what-do-we-do {
  overflow: hidden;
}

.services {
  background-color: var(--red);
  color: #fff;
  padding: 15px;
}

.services > p:nth-child(1) {
  background-color: gray;
  width: max-content;
  padding: 10px 30px 10px 15px;
  margin-left: -15px;
  border-bottom-right-radius: 50px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Solutions Tab Section */
#hero-solutions {
  background-image: url('./components/solutions-page-component/assets/img/pexels-pixabay-265087.jpg');
  background-attachment: fixed;
  height: 100vh;
  background-size: cover;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

#hero-solutions::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #000;
  opacity: .4;
}

.f2, .f3 {
  font-family: 'Poppins', 'Open Sans' !important;
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  z-index: 999;
}

/* Service Section Solution Page */
.service {
  margin-top: 5rem;
  overflow-x: hidden;
}

.f3 {
  font-size: 1.5rem;
}

.postcard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.card-postcard {
  flex: 1 1 0;
}

.card-postcard > h1{
  position: relative;
}

.card-postcard > h1::before {
  position: absolute;
  height: 1rem;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  width: 60%;
  color: var(--red);
}

/* Client Page */
#clients-page {
  margin-top: 100px;
  padding-top: 30px;
}

.headings > h3 {
  width: max-content;
}

.building-img-container {
  overflow: hidden;
}

.logos-building {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: fill;
  transition: 0.3s;
}

.building-img-container:hover > .logos-building,
.building-img-container:focus > .logos-building {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

/* Partial Client Component */ 
.f4 {
  font-family: 'Raleway', sans-serif;
}

/* Contact Page */

.contact-page {
  margin-top: 100px;
}

.map-container > iframe{
  width: 100%;
  height: 50vh;
}

.information {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infos {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contacts {
  display: flex;
}

.copy-btn {
  background-color: transparent;
  border: none;
  height: max-content;
}

.btn-to-top {
  position: fixed;
  right: 30px;
  bottom: 40px;
  border: none;
  background-color: transparent;
  z-index: 999;
}